
import { defineComponent } from "vue";
import Button from "primevue/button";
import Calendar from "primevue/calendar";
import Card from "primevue/card";
import Checkbox from "primevue/checkbox";
import Divider from "primevue/divider";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import TextArea from "primevue/textarea";
import Dialog from "primevue/dialog";
import Utils from "@/utility/utils";
import PosRow from "../DealSheet/PosRow.vue";
import Signature from "@/components/UI/Signature.vue";
import Footer from "@/components/Footer.vue";
import CollapsibleSection from "@/components/UI/CollapsibleSection.vue";
import { mapActions, mapGetters } from "vuex";
import CustomDataService from "@/services/CustomDataService";
import { cloneDeep } from "lodash";

const customDataService = new CustomDataService();

export default defineComponent({
  components: {
    Button,
    Calendar,
    Card,
    Divider,
    InputText,
    InputNumber,
    TextArea,
    Checkbox,
    PosRow,
    Signature,
    Dialog,
    Footer,
    CollapsibleSection,
  },
  props: {
    index: {
      type: [Number, Object],
      required: false,
      default: null,
    },
    cust_id: {
      type: String,
      default: null,
    },
  },
  mounted() {
    this.getDealSheetData();
    this.updateCustomerInfo();
    this.saveOriginalDealsheet();
  },
  data() {
    return {
      isDiscountIconDown: true as boolean,
      isRowsIconDown: true as boolean,
      visibleSubmittedBySignatureDialog: false,
      visibleApprovedBySignatureDialog: false,
      grabbedCustomer: [],
      dealsheet: {
        cust_name: "",
        cust_id: "",
        street_address: "",
        city: "",
        state: "",
        zip: "",
        num_of_shops: "",
        paint_supplier_name: "",
        paint_supplier_rep: "",
        paint_phone_num: "",
        if_sales_rep: "",
        agreement_start_date: "",
        agreement_end_date: "",
        if_investment: 0,
        supplier_name_investment: "",
        supplier_share_investment: 0,
        total_investment: 0,
        est_mo_paint_spend: 0,
        consignment_products: "",
        bodyshop_pricing: "",
        wholesale_pricing: "",
        notes: "",
        submitted_by: "",
        submitted_by_signature: {} as any,
        submitted_by_date: "",
        approved_by: "",
        approved_by_signature: {} as any,
        approved_by_date: "",
        month_end_credit_from_supplier_to_if: [
          {
            supplier_name: "",
            prod_line: "",
            sub_prod_line: "",
            supp_percent: 0,
          },
          {
            supplier_name: "",
            prod_line: "",
            sub_prod_line: "",
            supp_percent: 0,
          },
        ],
        month_end_credit_to_customer: {
          supplier_name: "",
          prod_line: "",
          sub_prod_line: "",
          if_percent: 0,
        },
        ar_credit: {
          supplier_name: "",
          prod_line: "",
          sub_prod_line: "",
          if_percent: 0,
        },
        pos: [
          {
            supplier_name: "",
            prod_line: "",
            sub_prod_line: "",
            supp_percent: 0,
            if_percent: 0,
          },
        ],
      },
      originalDealsheet: {} as any,
      isDealsheetSaved: false,
    };
  },
  computed: {
    ...mapGetters({
      getClient: "session/getClient",
      getCustomer: "customerInquiry/getCustomer",
      getCurrentCustomer: "customerInquiry/getCurrentCustomer",
      getCurrentCustTabIndex: "customerInquiry/getCurrentCustTabIndex",
    }),
    isEdit(): boolean {
      return this.$route.query.isEdit === "true";
    },
    dealSheetId(): any {
      return this.$route.query.dealSheetId;
    },
    contactsIconClass(): string {
      return this.isDiscountIconDown
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
    inputRowsIconClass(): string {
      return this.isRowsIconDown
        ? "pi pi-chevron-down ml-2"
        : "pi pi-chevron-right ml-2";
    },
  },
  watch: {
    getCurrentCustTabIndex() {
      this.updateCustomerInfo();
    },
    dealsheet: {
      handler: function (val: any, oldVal: any) {
        if (!this.isDealsheetSaved && val.cust_name) {
          this.saveOriginalDealsheet();
          this.isDealsheetSaved = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions({
      addNotification: "notification/add",
      fetchDealSheet: "dealSheet/fetchDealSheet",
    }),
    saveOriginalDealsheet() {
      this.originalDealsheet = cloneDeep(this.dealsheet);
    },
    async getDealSheetData() {
      try {
        await customDataService
          .getitem({
            filename: "DEALSHEET",
            requestSource: "test",
            id: this.dealSheetId,
          })
          .then((response) => {
            if (response) {
              this.grabbedCustomer = response.dealsheet_items;
              this.updateCustomerInfo();
            }
          });
      } catch (error) {
        this.addNotification({
          message: `Error getting Deal Sheet data: ${error}`,
          type: "error",
        });
      }
    },
    toggleIcon(tab: string) {
      switch (tab) {
        case "Discount":
          this.isDiscountIconDown = !this.isDiscountIconDown;
          break;
        case "inputRows":
          this.isRowsIconDown = !this.isRowsIconDown;
          break;
        default:
          tab;
      }
    },
    addNewPosRow(): void {
      this.dealsheet.pos.push({
        supplier_name: "",
        prod_line: "",
        sub_prod_line: "",
        supp_percent: 0,
        if_percent: 0,
      });
    },
    removeRow(index: number): void {
      if (index >= 0) {
        this.dealsheet.pos.splice(index, 1);
      }
    },
    formatDate(event: any, dateType: string) {
      const date = Utils.formatDate(event);
      switch (dateType) {
        case "agreement_start_date":
          this.dealsheet.agreement_start_date = date;
          break;
        case "agreement_end_date":
          this.dealsheet.agreement_end_date = date;
          break;
        case "approved_by_date":
          this.dealsheet.approved_by_date = date;
          break;
        case "submitted_by_date":
          this.dealsheet.submitted_by_date = date;
          break;
      }
    },
    showSubmittedBySignature() {
      this.visibleSubmittedBySignatureDialog = true;
    },
    saveSubmittedBy(signature: string) {
      this.dealsheet.submitted_by_signature = signature;
      this.visibleSubmittedBySignatureDialog = false;
    },
    showApprovedBySignature() {
      this.visibleApprovedBySignatureDialog = true;
    },
    saveApprovedBy(signature: string) {
      this.dealsheet.approved_by_signature = signature;
      this.visibleApprovedBySignatureDialog = false;
    },
    async submitDealSheet() {
      try {
        const customer = this.getCustomer(this.getCurrentCustTabIndex);

        await customDataService
          .postdata(
            this.isEdit
              ? {
                  filename: "DEALSHEET",
                  requestSource: "test",
                  record: this.dealsheet,
                  oldRecord: this.originalDealsheet,
                }
              : {
                  filename: "DEALSHEET",
                  requestSource: "test",
                  record: this.dealsheet,
                },
          )
          .then((response) => {
            if (response?.response?.recordId && !this.isEdit) {
              if (!customer.dealsheet_ids) {
                customer.dealsheet_ids = [];
              }
              customer.dealsheet_ids.push(response.response.recordId);
            }

            if (this.isEdit) {
              this.addNotification({
                message: `Updated Deal Sheet.`,
                type: "success",
              });
            } else {
              this.addNotification({
                message: `Created Deal Sheet.`,
                type: "success",
              });
            }
            this.clearDealSheet();
          })
          .finally(() => {
            this.$router.push("/customers/deal-sheet");
          });
      } catch (error) {
        this.addNotification({
          message: `Error submitting Deal Sheet: ${error}`,
          type: "error",
        });
      }
    },
    clearDealSheet() {
      this.dealsheet = {
        cust_name: "",
        cust_id: "",
        street_address: "",
        city: "",
        state: "",
        zip: "",
        num_of_shops: "",
        paint_supplier_name: "",
        paint_supplier_rep: "",
        paint_phone_num: "",
        if_sales_rep: "",
        agreement_start_date: "",
        agreement_end_date: "",
        if_investment: 0,
        supplier_name_investment: "",
        supplier_share_investment: 0,
        total_investment: 0,
        est_mo_paint_spend: 0,
        consignment_products: "",
        bodyshop_pricing: "",
        wholesale_pricing: "",
        notes: "",
        submitted_by: "",
        submitted_by_signature: {} as any,
        submitted_by_date: "",
        approved_by: "",
        approved_by_signature: {} as any,
        approved_by_date: "",
        month_end_credit_from_supplier_to_if: [
          {
            supplier_name: "",
            prod_line: "",
            sub_prod_line: "",
            supp_percent: 0,
          },
          {
            supplier_name: "",
            prod_line: "",
            sub_prod_line: "",
            supp_percent: 0,
          },
        ],
        month_end_credit_to_customer: {
          supplier_name: "",
          prod_line: "",
          sub_prod_line: "",
          if_percent: 0,
        },
        ar_credit: {
          supplier_name: "",
          prod_line: "",
          sub_prod_line: "",
          if_percent: 0,
        },
        pos: [
          {
            supplier_name: "",
            prod_line: "",
            sub_prod_line: "",
            supp_percent: 0,
            if_percent: 0,
          },
        ],
      };
    },
    updateMonthEndCredit(pos: any, index: number) {
      this.dealsheet.month_end_credit_from_supplier_to_if[index] = pos;
    },
    updateMonthEndCreditToCustomer(pos: any) {
      this.dealsheet.month_end_credit_to_customer = pos;
    },
    updateArCredit(pos: any) {
      this.dealsheet.ar_credit = pos;
    },
    updatePOS(pos: any, index: number) {
      this.dealsheet.pos[index] = pos;
    },
    updateCustomerInfo() {
      if (!this.isEdit) {
        const customer = this.getCustomer(this.getCurrentCustTabIndex);
        if (customer) {
          this.dealsheet.cust_id = customer.cust_id;
          this.dealsheet.cust_name = customer.name;
          this.dealsheet.street_address = customer.address_items
            .map((address: any) => address.address)
            .join(", ");
          this.dealsheet.zip = customer.sort_zip;
          this.dealsheet.state = customer.sort_state;
          this.dealsheet.city = customer.sort_city;
        }
      } else {
        this.grabbedCustomer.forEach((customer: any) => {
          if (customer.cust_id.toString() === this.cust_id) {
            this.dealsheet = customer;
          }
        });
      }
    },
  },
});
