import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "grid pos-row-wrapper w-full" }
const _hoisted_2 = { class: "col-2" }
const _hoisted_3 = {
  key: 0,
  class: "text-900 text-sm font-semibold"
}
const _hoisted_4 = { class: "col-2" }
const _hoisted_5 = { class: "col-2" }
const _hoisted_6 = { class: "col-2" }
const _hoisted_7 = { class: "col-2" }
const _hoisted_8 = { class: "col-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_InputNumber = _resolveComponent("InputNumber")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_ctx.rowLabel !== undefined)
        ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.rowLabel), 1))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_4, [
      (_ctx.data && _ctx.data.supplier_name !== undefined)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: _ctx.data.supplier_name,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data.supplier_name) = $event)),
            class: "text-sm text-left"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_5, [
      (_ctx.data && _ctx.data.prod_line !== undefined)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: _ctx.data.prod_line,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.data.prod_line) = $event)),
            class: "text-sm text-left"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_6, [
      (_ctx.data && _ctx.data.sub_prod_line !== undefined)
        ? (_openBlock(), _createBlock(_component_InputText, {
            key: 0,
            modelValue: _ctx.data.sub_prod_line,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.data.sub_prod_line) = $event)),
            class: "text-sm text-left"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_7, [
      (_ctx.data && _ctx.data.if_percent !== undefined)
        ? (_openBlock(), _createBlock(_component_InputNumber, {
            key: 0,
            modelValue: _ctx.data.if_percent,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.data.if_percent) = $event)),
            class: "text-sm text-left"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ]),
    _createElementVNode("div", _hoisted_8, [
      (_ctx.data && _ctx.data.supp_percent !== undefined)
        ? (_openBlock(), _createBlock(_component_InputNumber, {
            key: 0,
            modelValue: _ctx.data.supp_percent,
            "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.data.supp_percent) = $event)),
            class: "text-sm text-left"
          }, null, 8, ["modelValue"]))
        : _createCommentVNode("", true)
    ])
  ]))
}